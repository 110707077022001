<template>
  <q-page class="flex flex-center">
    <div class="column">
      <div class="text-center" style="margin-top: -200px">
        <!-- <img height="200" id="logo-wordmark" src="~assets/WINGS_FL_FC-square.png" alt="wings wordmark"></img> -->
      </div>
      <div class="text-center" style="padding-top: 20px">
        <q-progress stripe indeterminate color="primary" height="8px" style="margin-bottom:-8px;width:300px;margin-left:auto;margin-right:auto;"/>
      </div>
      <div class="text-center hidden" style="padding-top: 20px">
        <template v-if="!authenticated">
          <q-btn v-ripple big push color="primary" class="shadow-8" style="width:200px;" @click="auth.login">Login</q-btn>
        </template>
        <template v-else>
          <q-btn v-ripple big push color="primary" class="shadow-8" style="width:200px;" @click="auth.logout">Logout</q-btn>
        </template>
      </div>
    </div>
  </q-page>
</template>
<script>
import AuthService from '../services/AuthService'
const auth = new AuthService()
const { login, logout, authenticated, authNotifier } = auth
export default {
  name: 'PageLogin',
  mounted () {
    if (this.mounted) return
    this.mounted = true
    // script src="https://auth.magic.link/pnp/login" data-magic-publishable-api-key="pk_live_021C33000C015223" data-redirect-uri="/callback"
    // create
    const scriptEl = document.createElement('script')
    scriptEl.src = 'https://auth.magic.link/pnp/login'
    scriptEl.setAttribute('data-magic-publishable-api-key', 'pk_live_021C33000C015223')
    scriptEl.setAttribute('data-redirect-uri', '/callback')
    // append
    document.getElementsByTagName('body')[0].appendChild(scriptEl)
    // adjust layout
    setInterval(() => {
      let magicIframes = document.getElementsByClassName('magic-iframe')
      if (magicIframes.length) {
        let magicIframe = magicIframes[magicIframes.length - 1]
        magicIframe.style.zIndex = 100
        magicIframe.style.top = '30px'
      }
      // document.getElementById('appHeader').style.zIndex = 2147483647 + 10
    }, 1000)
  },
  data () {
    authNotifier.on('authChange', authState => {
      this.authenticated = authState.authenticated
    })
    return {
      mounted: false,
      auth,
      authenticated
    }
  },
  methods: {
    login,
    logout,
    processLogin () {
      auth.login()
    }
  }
}
</script>
