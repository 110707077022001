var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-page", { staticClass: "flex flex-center" }, [
    _c("div", { staticClass: "column" }, [
      _c("div", {
        staticClass: "text-center",
        staticStyle: { "margin-top": "-200px" }
      }),
      _c(
        "div",
        { staticClass: "text-center", staticStyle: { "padding-top": "20px" } },
        [
          _c("q-progress", {
            staticStyle: {
              "margin-bottom": "-8px",
              width: "300px",
              "margin-left": "auto",
              "margin-right": "auto"
            },
            attrs: {
              stripe: "",
              indeterminate: "",
              color: "primary",
              height: "8px"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "text-center hidden",
          staticStyle: { "padding-top": "20px" }
        },
        [
          !_vm.authenticated
            ? [
                _c(
                  "q-btn",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    staticClass: "shadow-8",
                    staticStyle: { width: "200px" },
                    attrs: { big: "", push: "", color: "primary" },
                    on: { click: _vm.auth.login }
                  },
                  [_vm._v("Login")]
                )
              ]
            : [
                _c(
                  "q-btn",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    staticClass: "shadow-8",
                    staticStyle: { width: "200px" },
                    attrs: { big: "", push: "", color: "primary" },
                    on: { click: _vm.auth.logout }
                  },
                  [_vm._v("Logout")]
                )
              ]
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }